import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, NavLink } from "react-router-dom";
import {
  FaUser,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaTachometerAlt,
  FaNewspaper,
  FaUserFriends,
  FaReceipt,
  FaStaylinked,
  FaSnapchat,
  FaComment,
  FaBell,
  FaChartBar,
  FaBullhorn,
  FaSignOutAlt,
  FaImage,
  FaQuestion,
} from "react-icons/fa";
import useLogout from "../Hooks/useLogout";

import { useNavigate } from "react-router-dom";

const SideMenu = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const sidebarWidth = 200;
  return (
    <Sidebar
      width={sidebarWidth}
      style={{ height: "100%" }}
      collapsed={collapsed}
      toggled={toggled}
      handleToggleSidebar={handleToggleSidebar}
    >
      <Menu>
        {collapsed ? (
          <MenuItem
            icon={<FaAngleDoubleRight />}
            onClick={handleCollapsedChange}
          ></MenuItem>
        ) : (
          <MenuItem
            suffix={<FaAngleDoubleLeft />}
            onClick={handleCollapsedChange}
          >
            <div
              style={{
                padding: "9px",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: 15,
                letterSpacing: "1px",
              }}
            >
              Daily Coffee App
            </div>
          </MenuItem>
        )}

        <MenuItem component={<Link to="/" />} icon={<FaTachometerAlt />}>
          Dashboard
        </MenuItem>
        <SubMenu label={"Report"} icon={<FaChartBar />}>
          <MenuItem component={<Link to="/AppsalesReport" />}>
            App Sales
          </MenuItem>
          <MenuItem component={<Link to="/DailyBalanceReport" />}>
            Daily Balance
          </MenuItem>
          <MenuItem component={<Link to="/FeedbacksReport" />}>
            Feedbacks/Rating
          </MenuItem>
          {/* only seasonal and default banner, do not add */}
          {/* <MenuItem component={<Link to="/addFooterBanner" />}>
            Add Banner Footer
          </MenuItem> */}
        </SubMenu>
        <SubMenu label={"News"} icon={<FaNewspaper />}>
          <MenuItem component={<Link to="/manageNews" />}>
            News Management
          </MenuItem>
          <MenuItem component={<Link to="/addNews" />}>Add News</MenuItem>
        </SubMenu>
        <SubMenu label={"Footer Banner"} icon={<FaImage />}>
          <MenuItem component={<Link to="/manageFooterBanner" />}>
            Footer Banner Management
          </MenuItem>

          {/* only seasonal and default banner, do not add */}
          {/* <MenuItem component={<Link to="/addFooterBanner" />}>
            Add Banner Footer
          </MenuItem> */}
        </SubMenu>
        <SubMenu label={"Users"} icon={<FaUserFriends />}>
          <MenuItem component={<Link to="/manageUser" />}>
            Users Management
          </MenuItem>
        </SubMenu>
        <SubMenu label={"FAQ"} icon={<FaQuestion />}>
          <MenuItem component={<Link to="/manageFAQ" />}>
            FAQ Management
          </MenuItem>
        </SubMenu>
        <MenuItem icon={<FaBell />} component={<Link to="/sendNotification" />}>
          Notification
        </MenuItem>
        <SubMenu label={"Admin User"} icon={<FaUser />}>
          <MenuItem component={<Link to="/manageAdmin" />}>
            Admin Management
          </MenuItem>
          <MenuItem component={<Link to="/addAdmin" />}>Add Admin</MenuItem>
        </SubMenu>
        <MenuItem
          onClick={() => {
            signOut();
          }}
          icon={<FaSignOutAlt />}
        >
          Sign Out
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideMenu;
