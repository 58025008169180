export const getChartDimensions = () => {
  if (window.innerWidth <= 600) {
    return { width: 250, height: 250 };
  } else if (window.innerWidth <= 1024) {
    return { width: 350, height: 200 };
  } else if (window.innerWidth <= 1366) {
    return { width: 500, height: 300 };
  } else if (window.innerWidth <= 1920) {
    return { width: 750, height: 400 };
  } else {
    return { width: 900, height: 500 }; // Default dimensions
  }
};
